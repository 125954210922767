// set basepath of project for webpack
import { basePath } from './core/config'
__webpack_public_path__ = `${basePath}/assets/frontend/js/`

import 'console-polyfill'
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)
import 'custom-event-polyfill'
import './core/Sentry'

import $ from 'jquery'

// jquery utils
import 'jquery.easing'

import './plugins/nette.ajax'
import './plugins/nette.ajax.history'

// nette
import './nette/NetteAutosubmit'
import './nette/NetteValidationMessages'
import './nette/NetteSnippets'
import './nette/NetteEvents'
import './nette/NetteLoading'

import Nette from './nette/NetteForms'
window.Nette = Nette
Nette.initOnLoad()

if ($.nette) {
    $.nette.init()
}

import { android, iOS, CMS } from './core/config'
import ScrollService, { scrollToElement } from './services/Scroll'

if (android || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
    ScrollService.useNative()
    setScrollPosition()
} else {
    ScrollService.useVirtual()
        .catch(() => ScrollService.useNative())
        .finally(() => setScrollPosition())
}

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import { loadComponents, removeComponents } from './core/componentUtils'
loadComponents()

/**
 * lazy loading
 */
import lazySizes from 'lazysizes'
lazySizes.cfg.lazyClass = 'is-lazy'
lazySizes.cfg.loadingClass = 'is-lazy-loading'
lazySizes.cfg.loadedClass = 'is-lazy-loaded'

import Swup from 'swup'
import SwupGtmPlugin from '@swup/gtm-plugin'
import SwupBodyClassPlugin from '@swup/body-class-plugin'
import { queryAll } from './utils/dom'
import EventBus from './core/EventBus'
import { EVENTS } from './core/Events'
import { runScriptsInsideElement } from './utils/script'

function setScrollPosition(x = null, y = null) {
    const id = window.location.hash.replace('#', '')
    const anchor = id ? document.getElementById(id) : null

    if (id && anchor) {
        const animate = ScrollService.isVirtual ? false : true
        ScrollService.scrollToElement(anchor, { animate: animate, mode: 'auto' })
    } else if (x !== null && y !== null) {
        ScrollService.setPosition(x, y)
    } else {
        //default chovani prohlizece
    }
}

let isTabSwup = false
const lastScrollPosition = {
    x: 0,
    y: 0,
}

if (!CMS) {
    const linkSelector =
        'a' +
        [
            '[href^="#"]',
            '[href^="http"]',
            '[href^="/files"]',
            '[data-no-swup]',
            '[target="_blank"]',
            '.ajax',
            '[href^="mailto"]',
            '[href^="tel"]',
        ]
            .map((selector) => `:not(${selector})`)
            .join('')

    const swup = new Swup({
        linkSelector,
        cache: false,
        preload: false,
        animationSelector: '.scroll-Container-content',
        plugins: [new SwupBodyClassPlugin(), new SwupGtmPlugin()],
        containers: ['#swup', '#doubleReservation', '#language-switch'],
        skipPopStateHandling: (event) => {
            if (event?.state?.source === 'swup') {
                return false
            }
            return true
        },
    })

    swup.on('clickLink', (event) => {
        //pokud swupuje mezi taby, tak drzet pozici scrollu
        isTabSwup = event.delegateTarget.dataset.swupTransition === 'swup-tab' ? true : false

        EventBus.emit(EVENTS.SWUP_CLICK_LINK, { event })
    })

    swup.on('willReplaceContent', (event) => {
        if (isTabSwup) {
            lastScrollPosition.x = ScrollService.getScroll().x
            lastScrollPosition.y = ScrollService.getScroll().y
        } else {
            lastScrollPosition.x = 0
            lastScrollPosition.y = 0
        }

        EventBus.emit(EVENTS.SWUP_WILL_REPLACE_CONTENT)

        queryAll('[data-swup]').forEach((element) => {
            removeComponents(element)
        })
    })

    swup.on('contentReplaced', (event) => {
        Nette.reinit()

        if (android || iOS || CMS || (typeof DISABLE_SMOOTH_SCROLL !== 'undefined' && DISABLE_SMOOTH_SCROLL) || document.body.classList.contains('u-nativeScroll')) {
            ScrollService.useNative()
        } else {
            ScrollService.useVirtual()
                .catch(() => ScrollService.useNative())
        }

        EventBus.emit(EVENTS.SWUP_CONTENT_REPLACED)

        queryAll('[data-swup]').forEach((element) => {
            loadComponents(element)

            //na bookine a voucherpage poustet scripty rovnou a necekat na komponenty
            if (
                document.body.classList.contains('booking-Page') ||
                document.body.classList.contains('voucher-Page')
            ) {
                runScriptsInsideElement(element)
            }

            //poustet sojern scripty po swupu na kazdy strance
            const sojernEl = document.getElementById('sojern')
            if (sojernEl) {
                runScriptsInsideElement(element)
            }
        })

        if ($.nette) {
            $.nette.load()
        }

        setTimeout(() => {
            ScrollService.resize()
            setScrollPosition(lastScrollPosition.x, lastScrollPosition.y)
        }, 0)
    })
}
